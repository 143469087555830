<template>
  <v-container class="max-width-1185px">
    <v-row class="text-center">
      <v-col class="mb-4 mt-4" cols="12">
        <h1 class="d-none display-1 font-weight-bold mb-3">
          Buchen Sie schnell und einfach Ihre Probefahrt
        </h1>
      </v-col>

      
      <v-col class="mb-5" cols="12">
        <h2 class="headline font-weight-bold mb-3">{{ car_text_setting_data.type_select_text }}</h2>
        
          

            <carousel :mouseDrag="false" :paginationEnabled="false" :navigationEnabled="true" :navigation-next-label="navigationNext" :navigation-prev-label="navigationPrev" :perPageCustom="[[300, 3],[600, 3], [786, 5], [1080, 7]]">
              <slide v-for="n in vehicle_details"
                :key="n.id"
                :data-value="n"
                >
                <v-item v-slot="{ toggle }">
                  <v-card
                    :outlined="active_type_id === n.name? true : false"
                    class=""
                    elevation="0"
                    @click="toggle;activate_type(n)"
                    :id="n.name"
                  >
                    <div align="center">
                      <v-img
                        class="d-block mb-2 vehicle_type_image"
                        :src="base_url + n.icon"
                      />
                      <label>{{ n.name }}</label>
                    </div>
                  </v-card>
                </v-item>
              </slide>
            </carousel>
      </v-col>

      <!-- <v-col class="mb-5" cols="12">
        <h2 class="headline font-weight-bold mb-3">Fahrzeug-Typ wählen:</h2>
        <v-row justify="center">
          <v-sheet class="mx-auto" max-width="1200" min-width="400">
            <v-slide-group v-model="value_type" class="pa-4" show-arrows>
              <v-slide-item
                v-for="n in vehicle_details"
                :key="n.id"
                v-slot="{ active, toggle }"
                :value="n"
              > -->
                <!-- active ? outlined  -->
                <!-- :color="active ? 'primary' : ''" -->
                <!-- <v-card
                  :outlined="active ? true : false"
                  class="pa-3"
                  elevation="0"
                  @click="toggle()"
                >
                  <div align="center">
                    <v-img
                      width="100"
                      class="d-block mb-2"
                      :src="base_url + n.icon"
                    />
                    <label>{{ n.name }}</label>
                  </div>
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </v-sheet>
        </v-row>
      </v-col> -->

      <v-col class="mb-5 d-block d-xs-block d-sm-block d-md-none" cols="12">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <h2 class="headline font-weight-bold mb-3">{{ car_text_setting_data.filter_text_mobile }}</h2>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row align="center">
                <v-col class="d-flex" cols="12" sm="4">
                  <v-select
                    :items="items_model"
                    v-model="value_model"
                   :label="car_text_setting_data.model_text"
                    outlined
                    class="text-uppercase"
                  >
                    <template slot="no-data">
                      <v-list-item>keine Einträge</v-list-item>
                    </template>
                  </v-select>
                </v-col>
                <v-col class="d-flex" cols="12" sm="4">
                  <v-select
                    :items="items_transmission"
                    v-model="value_transmission"
                    :label="car_text_setting_data.gear_text"
                    outlined
                    class="text-uppercase"
                  >
                    <template slot="no-data">
                      <v-list-item>keine Einträge</v-list-item>
                    </template></v-select
                  >
                </v-col>
                <v-col class="d-flex" cols="12" sm="4">
                  <v-select
                    :items="items_type_of_fuel"
                    v-model="value_type_of_fuel"
                    :label="car_text_setting_data.fuel_text"
                    outlined
                    class="text-uppercase"
                  >
                    <template slot="no-data">
                      <v-list-item>keine Einträge</v-list-item>
                    </template></v-select
                  >
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        </v-col>

        <v-col class="mb-5 d-none d-md-block" cols="12">
        <h2 class="headline font-weight-bold mb-3">{{ car_text_setting_data.brand_select_text }}</h2>
        <v-row align="center">
          <v-col class="d-flex" cols="12" sm="4">
            <v-select
              :items="items_model"
              v-model="value_model"
              :label="car_text_setting_data.model_text"
              outlined
              class="text-uppercase"
            >
              <template slot="no-data">
                <v-list-item>keine Einträge</v-list-item>
              </template>
            </v-select>
          </v-col>
          <v-col class="d-flex" cols="12" sm="4">
            <v-select
              :items="items_transmission"
              v-model="value_transmission"
             :label="car_text_setting_data.gear_text"
              outlined
              class="text-uppercase"
            >
              <template slot="no-data">
                <v-list-item>keine Einträge</v-list-item>
              </template></v-select
            >
          </v-col>
          <v-col class="d-flex" cols="12" sm="4">
            <v-select
              :items="items_type_of_fuel"
              v-model="value_type_of_fuel"
              :label="car_text_setting_data.fuel_text"
              outlined
              class="text-uppercase"
            >
              <template slot="no-data">
                <v-list-item>keine Einträge</v-list-item>
              </template></v-select
            >
          </v-col>
        </v-row>
      </v-col>


      <v-col class="mb-5" cols="12">

        <h2 v-if="isNoVehicle" class="headline font-weight-bold mb-3">{{ car_text_setting_data.no_matching_results }}</h2>

        <h2 class="headline font-weight-bold mb-3" v-if="!isNoVehicle">
          {{ car_text_setting_data.car_select_text }}
        </h2>

        <!-- check carousel code here -->
         <v-row>
        <v-col>
          <carousel :mouseDrag="false" :paginationEnabled="false" :navigationEnabled="true" :navigation-next-label="navigationNext" :navigation-prev-label="navigationPrev" :perPageCustom="[[300, 1],[600, 2], [786, 2], [1150, 3]]">
            <slide v-for="n in vehicleResult"
                :key="n.id"
                :data-value="n"
                >
                <v-item v-slot="{ toggle }">
                  <v-card
                    :outlined="activate === n.name? true : false"
                    class="mx-auto pa-2 justify-center text-center car-card"
                    elevation="0"
                    @click="toggle;activate(n)"
                    :id="n.name"
                  >
                    <div class="" align="center" justify="center">
                      <v-img
                        class="d-block"
                        :src="base_url + n.car_photo"
                      />
                      <p class="text-h6 ma-0 text-uppercase">
                        {{ n.brand + " " + n.model }}
                      </p>
                      <label class="text-caption">{{
                        n.model_and_description
                      }}</label>
                      <label class="text-caption">{{
                        n.ps +
                        " | " +
                        n.type_of_fuel +
                        " | " +
                        n.transmission +
                        " | " +
                        n.color
                      }}</label>
                      <br />
                      <label class="location-label" for="">{{
                        n.location_frontend_label
                      }}</label>
                    </div>
                  </v-card>
               </v-item>
            </slide>
          </carousel>
        </v-col>
      </v-row>

        <!-- <v-row justify="center">
          <v-sheet class="mx-auto" max-width="1200" min-width="100%">
             <v-slide-group v-model="value_vehicle" mobile-breakpoint="lg" class="pa-4" show-arrows :key="vehicleResult.length">
              <v-slide-item
               v-for="n in vehicleResult"
                :key="n.id"
                v-slot="{ active, toggle }"
                :value="n"
              > -->
            <!-- <v-slide-group  class="pa-4" show-arrows> -->
            <!-- <v-item-group v-model="value_vehicle" active-class=""> -->
              <!-- <v-slide-item -->
              <!-- <v-card elevation="5" style="height: 550px; overflow-y: scroll">
                <v-container>
                  <v-row>
                    <v-col
                      v-for="n in vehicleResult"
                      :key="n.id"
                      cols="12"
                      md="3"
                      sm="6"
                      xs="12"
                      class="pb-0"
                    > -->
                      <!-- <v-item v-slot="{ active, toggle }" :value="n"> -->
                        <!-- :color="active ? 'primary' : ''" -->
                        <!-- <v-card
                          :outlined="active ? true : false"
                          class="pa-2 justify-center text-center car-card"
                          elevation="0"
                          @click="toggle()"
                          :id="n.name"
                        >
                          <div class="" align="center" justify="center">
                            <v-img
                              class="d-block"
                              :src="base_url + n.car_photo"
                            />
                            <p class="text-h6 ma-0 text-uppercase">
                              {{ n.brand + " " + n.model }}
                            </p>
                            <label class="text-caption">{{
                              n.model_and_description
                            }}</label>
                            <label class="text-caption">{{
                              n.ps +
                              " | " +
                              n.type_of_fuel +
                              " | " +
                              n.transmission +
                              " | " +
                              n.color
                            }}</label>
                            <br />
                            <label class="location-label" for="">{{
                              n.location_frontend_label
                            }}</label>
                          </div>
                        </v-card> -->
                      <!-- </v-item> -->
                    <!-- </v-col> -->
                  <!-- </v-row> -->
                <!-- </v-container> -->
              <!-- </v-card> -->
            <!-- </v-item-group> -->
             <!-- </v-slide-item>
            </v-slide-group>
          </v-sheet>
        </v-row> -->
      </v-col>

      <!-- <v-col class="mb-15" cols="12">
        <h2 class="headline font-weight-bold mb-10">
          Wann wollen Sie Probefahren?
        </h2>

        <v-row justify="center">
          <v-col class="custom-datepicker">
            <v-date-picker
              style="font-size: 16px"
              :min="current_date"
              v-model="value_datePicker"
              :first-day-of-week="1"
              locale="de-DE"
            ></v-date-picker>
          </v-col>
          <v-col>
            <v-row justify="start" align="center">
              <v-chip-group
                active-class="primary--text"
                v-model="value_selected_slot"
                column
              >
                <v-chip
                  large
                  class="ma-2"
                  :value="n"
                  filter
                  v-for="n in items_available_slots"
                  :key="n.title"
                  :disabled="check_slot_booked(n)"
                >
                  {{ n.title }}
                </v-chip>
              </v-chip-group>
            </v-row>
          </v-col>
        </v-row>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import { Carousel, Slide } from 'vue-carousel';

export default {
  name: "Step2",
  components: {
    Carousel,
    Slide
  },
  props: ['set_step1_data','car_text_setting_data'],
  methods: {
    ...mapActions([
      "get_car_type_model_details",
      "get_details",
      "get_car_details",
      "get_available_slot",
    ]),

    activate(data) {
      this.value_vehicle = data;
      this.activeId = data.name
    },
    activate_type(data) {
      this.value_type = data;
      this.active_type_id = data.name
    },
    prepare_car_type_model_details: function () {
      this.get_car_type_model_details().then((response) => {
        this.car_type_model_details = response;
        this.vehicle_details = response.vehicle_details;
        this.brand_details = response.brand_details;
        this.vehicle_type_filter = response.vehicle_type_filter;
        this.brand_details_filter = response.brand_details_filter;
        this.enabled_brand_details = response.brand_details_filter;

        if (!this.$route.query.utm_marke){
          this.items_model = response.filter_details.model;
          this.items_transmission = response.filter_details.transmission;
          this.items_type_of_fuel = response.filter_details.type_of_fuel;
        } 
      });
    },
    prepare_car_details: function () {
      this.get_car_details().then((response) => {
        this.vehicleResult = response;
        if (!this.vehicleResult.length) {
          this.isNoVehicle = true
          } else {
            this.isNoVehicle = false
          }
      });
    },
    
    check_brand_enabled: function (brand) {
      if (typeof brand.enable !== "undefined") {
        if (brand.enable === 1) {
          return false;
        } else {
          return true;
        }
      }
    },
    
    // handleSlideClick: function (data) {
    //     console.log(Object.keys(data['value']));
    //     this.value_vehicle = data['value']
    // },

    // check_slot_booked: function (slot) {
    //   if (slot.booked === 1) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
  },
  created() {
    
    // this.prepare_car_details();
  },
  computed: {
    navigationNext: function() { return `<i class='v-icon notranslate mdi mdi-chevron-right theme--light' style='font-size: 50px;'></i>` },
    navigationPrev: function() { return `<i class='v-icon notranslate mdi mdi-chevron-left theme--light' style='font-size: 50px;'></i>` },
  },
  async mounted() {
    await this.prepare_car_type_model_details();
    // var self = this;
    // if (
    //   this.$route.query.utm_marke ||
    //   this.$route.query.utm_model ||
    //   this.$route.query.utm_getriebe ||
    //   this.$route.query.utm_campaign
    // ) {

    //   if(this.$route.query.utm_marke){
    //     // set model transmission nad fuel based on Brand
    //     let car_type_details = {
    //       brand: this.$route.query.utm_marke,
    //     };
    //     this.get_details(car_type_details).then( async (response) => {
    //       // this.items_model = null;
    //       this.items_model = response.model;
    //       this.items_transmission = response.transmission;
    //       this.items_type_of_fuel = response.type_of_fuel;
    //     });
      
    //   }
    //   this.value_brand = this.$route.query.utm_marke;
    //   this.value_model = this.$route.query.utm_model;
    //   this.value_transmission = this.$route.query.utm_getriebe;
    //   this.value_type_of_fuel = this.$route.query.utm_campaign;

    //   let car_details = {
    //     model: this.$route.query.utm_model,
    //     brand: this.$route.query.utm_marke,
    //     transmission: this.$route.query.utm_getriebe,
    //     type_of_fuel: this.$route.query.utm_campaign,
    //   };

    //   this.get_car_details(car_details).then((response) => {
    //     this.vehicleResult = response;
        
    //   });

    //   this.book_model_data.value_brand = this.$route.query.utm_marke;
    //   this.book_model_data.value_model = this.$route.query.utm_model;
    //   this.book_model_data.value_transmission = this.$route.query.utm_getriebe;
    //   this.book_model_data.value_type_of_fuel = this.$route.query.utm_campaign;

    //   this.$nextTick(() => {
    //     //with this we skip the first change
    //     this.watcher_status = true;
    //   });

    //   setTimeout(() => {
    //     // this.$refs[this.$route.query.utm_marke][0].outlined = true;
    //     this.$refs[this.$route.query.utm_marke][0].isActive = true;
    //   }, 1200);
    // } else {
    //   this.watcher_status = true;
    //   // this.prepare_car_details();
    // }

    // ?utm_source=NeuFahrzeuge&utm_marke=Skoda&utm_model=ENYAQ&utm_getriebe=Automatikgetriebe&utm_campaign=Elektro
    // model=ENYAQ&transmission=Automatikgetriebe&type_of_fuel=Elektro&brand=Skoda
    // this.watcher_status = true;

    // alert(this.value_brand.name);
    // console.log(this.$route.query.brand);
    // JSONObject json = new JSONObject();
    // json.put("name", "student");

    // console.log(JSON.parse({"name": this.$route.query.brand}))
  },
  watch: {
    set_step1_data(data){
      // this.value_type = null
      // this.value_model = null;
      // this.value_transmission = null;
      // this.value_type_of_fuel = null;
      // this.value_selected_slot = "";
      
      this.value_brand = data

      this.get_details({
          brand: this.value_brand.name,
      }).then((response) => {
        this.items_model = response.model;
        this.items_transmission = response.transmission;
        this.items_type_of_fuel = response.type_of_fuel;
      });
      let car_type_details = {
          brand: this.value_brand.name,
        };
        this.get_car_details(car_type_details).then((response) => {
          this.vehicleResult = response;
          if (!this.vehicleResult.length) {
          this.isNoVehicle = true
          } else {
            this.isNoVehicle = false
          }
        });
    },
    value_type(newValue) {
      // if (!this.watcher_status) {
      //   return false;
      // }
      //called whenever value_type changes

      // this.value_brand = "";
      this.value_vehicle = "";
      this.value_model = null;
      this.value_transmission = null;
      this.value_type_of_fuel = null;
      this.value_selected_slot = "";
      // this.items_model = [];
      // this.items_transmission = [];
      // this.items_type_of_fuel = [];
      this.items_available_slots = [];
      this.vehicleResult = [];
      // if (newValue == null) {
      //   return false;
      // }

      if (typeof newValue == "undefined") {
        
        this.brand_details = this.car_type_model_details.brand_details;
        this.book_model_data.value_type = null;
        let car_type_details = {
          brand: this.value_brand.name,
        };
        this.get_car_details(car_type_details).then((response) => {
          this.vehicleResult = response;
          if (!this.vehicleResult.length) {
          this.isNoVehicle = true
          } else {
            this.isNoVehicle = false
          }
        });

        this.get_details({
          brand: this.value_brand.name,
        }).then((response) => {
          this.items_model = response.model;
          this.items_transmission = response.transmission;
          this.items_type_of_fuel = response.type_of_fuel;
        });

        // this.items_model = this.car_type_model_details.filter_details.model;
        // this.items_transmission =
        //   this.car_type_model_details.filter_details.transmission;
        // this.items_type_of_fuel =
        //   this.car_type_model_details.filter_details.type_of_fuel;
      } else {
        this.brand_details = this.vehicle_type_filter[newValue.name];
        this.book_model_data.value_type = newValue;

        let car_type_details = {
          vehicle_type: this.value_type.name,
          brand: this.value_brand.name,
        };
        this.get_car_details(car_type_details).then((response) => {
          this.vehicleResult = response;
          if (!this.vehicleResult.length) {
          this.isNoVehicle = true
          } else {
            this.isNoVehicle = false
          }
          
        });

        this.get_details({
          vehicle_type: this.value_type.name,
          brand: this.value_brand.name,
        }).then((response) => {
          this.items_model = response.model;
          this.items_transmission = response.transmission;
          this.items_type_of_fuel = response.type_of_fuel;
        });
      }
    },
    value_brand(newValue) {
      // if (!this.watcher_status) {
      //   return false;
      // }
      this.value_type = ""
      this.book_model_data.value_type = null;
      this.value_vehicle = "";
      this.value_model = null;
      this.value_transmission = null;
      this.value_type_of_fuel = null;
      this.value_selected_slot = "";
      //   this.items_model = [];
      //   this.items_transmission = [];
      //   this.items_type_of_fuel = [];
      this.items_available_slots = [];
      this.vehicleResult = [];
      this.book_model_data.value_brand = newValue;
      if (
        typeof this.value_type == "object" &&
        typeof this.value_brand == "object"
      ) {
        let car_type_details = {
          vehicle_type: this.value_type.name,
          brand: this.value_brand.name,
        };
        this.get_details(car_type_details).then((response) => {
          this.items_model = response.model;
          this.items_transmission = response.transmission;
          this.items_type_of_fuel = response.type_of_fuel;
        });
        // this.book_model_data.value_brand = newValue;
        this.get_car_details(car_type_details).then((response) => {
          this.vehicleResult = response;
          if (!this.vehicleResult.length) {
          this.isNoVehicle = true
          } else {
            this.isNoVehicle = false
          }
          
        });
      } else if (typeof this.value_brand == "object") {
        //   if only brand is selected and type is null
        let car_type_details = {
          brand: this.value_brand.name,
        };
        this.get_details(car_type_details).then((response) => {
          this.items_model = response.model;
          this.items_transmission = response.transmission;
          this.items_type_of_fuel = response.type_of_fuel;
        });

        this.get_car_details(car_type_details).then((response) => {
          this.vehicleResult = response;
          if (!this.vehicleResult.length) {
          this.isNoVehicle = true
          } else {
            this.isNoVehicle = false
          }
          
        });
      } else if (typeof this.value_type == "object") {
        // if only type is selected and brand is null
        let car_type_details = {
          vehicle_type: this.value_type.name,
        };
        this.get_details(car_type_details).then((response) => {
          this.items_model = response.model;
          this.items_transmission = response.transmission;
          this.items_type_of_fuel = response.type_of_fuel;
        });

        this.get_car_details(car_type_details).then((response) => {
          this.vehicleResult = response;
          if (!this.vehicleResult.length) {
          this.isNoVehicle = true
          } else {
            this.isNoVehicle = false
          }
          
        });
      } else {
        // if brand and type is null
        this.items_model = this.car_type_model_details.filter_details.model;
        this.items_transmission =
          this.car_type_model_details.filter_details.transmission;
        this.items_type_of_fuel =
          this.car_type_model_details.filter_details.type_of_fuel;

        this.book_model_data.value_brand = null;
        this.get_car_details().then((response) => {
          this.vehicleResult = response;
          if (!this.vehicleResult.length) {
          this.isNoVehicle = true
          } else {
            this.isNoVehicle = false
          }
          
        });
      }
    },
    value_model() {
      // if (!this.watcher_status) {
      //   return false;
      // }
      if (
        typeof this.value_model == "string"
        // typeof this.value_transmission == "string" &&
        // typeof this.value_type_of_fuel == "string"
      ) {
        let car_details = {
          model: this.value_model,
          transmission: this.value_transmission,
          type_of_fuel: this.value_type_of_fuel,
        };

        if (typeof this.value_type == "object") {
          car_details["vehicle_type"] = this.value_type.name;
        }
        if (typeof this.value_brand == "object") {
          car_details["brand"] = this.value_brand.name;
        }

        this.get_car_details(car_details).then((response) => {
          this.vehicleResult = response;
          if (!this.vehicleResult.length) {
          this.isNoVehicle = true
          } else {
            this.isNoVehicle = false
          }
          
        });
        this.book_model_data.value_model = this.value_model;
        // this.book_model_data.value_transmission = this.value_transmission;
        // this.book_model_data.value_type_of_fuel = this.value_type_of_fuel;
      } else {
        this.book_model_data.value_model = null;
        // this.book_model_data.value_transmission = null;
        // this.book_model_data.value_type_of_fuel = null;
      }
    },
    value_transmission() {
      // if (!this.watcher_status) {
      //   return false;
      // }
      if (
        // typeof this.value_model == "string" &&
        typeof this.value_transmission == "string"
        // typeof this.value_type_of_fuel == "string"
      ) {
        let car_details = {
          model: this.value_model,
          transmission: this.value_transmission,
          type_of_fuel: this.value_type_of_fuel,
        };
        if (typeof this.value_type == "object") {
          car_details["vehicle_type"] = this.value_type.name;
        }
        if (typeof this.value_brand == "object") {
          car_details["brand"] = this.value_brand.name;
        }

        this.get_car_details(car_details).then((response) => {
          this.vehicleResult = response;
          if (!this.vehicleResult.length) {
          this.isNoVehicle = true
          } else {
            this.isNoVehicle = false
          }
          
        });
        // this.book_model_data.value_model = this.value_model;
        this.book_model_data.value_transmission = this.value_transmission;
        // this.book_model_data.value_type_of_fuel = this.value_type_of_fuel;
      } else {
        // this.book_model_data.value_model = null;
        this.book_model_data.value_transmission = null;
        // this.book_model_data.value_type_of_fuel = null;
      }
    },
    value_type_of_fuel() {
      // if (!this.watcher_status) {
      //   return false;
      // }
      if (
        // typeof this.value_model == "string" &&
        // typeof this.value_transmission == "string" &&
        typeof this.value_type_of_fuel == "string"
      ) {
        let car_details = {
          model: this.value_model,
          transmission: this.value_transmission,
          type_of_fuel: this.value_type_of_fuel,
        };
        if (typeof this.value_type == "object") {
          car_details["vehicle_type"] = this.value_type.name;
        }
        if (typeof this.value_brand == "object") {
          car_details["brand"] = this.value_brand.name;
        }

        this.get_car_details(car_details).then((response) => {
          this.vehicleResult = response;
          if (!this.vehicleResult.length) {
          this.isNoVehicle = true
          } else {
            this.isNoVehicle = false
          }
          
        });
        // this.book_model_data.value_model = this.value_model;
        // this.book_model_data.value_transmission = this.value_transmission;
        this.book_model_data.value_type_of_fuel = this.value_type_of_fuel;
      } else {
        // this.book_model_data.value_model = null;
        // this.book_model_data.value_transmission = null;
        this.book_model_data.value_type_of_fuel = null;
      }
    },
    value_vehicle(newValue) {
      this.value_selected_slot = "";
      this.items_available_slots = [];

      if (
        typeof this.value_vehicle == "object" &&
        typeof this.value_datePicker == "string"
      ) {
        // let car_details = {
        //   car: this.value_vehicle.name,
        //   date: this.value_datePicker,
        // };
        // this.get_available_slot(car_details).then((response) => {
        //   this.items_available_slots = response;
        // });
        this.book_model_data.value_vehicle = newValue;
        this.$emit("step2-data", this.book_model_data); 
        this.$emit("allow-step-three", true);
      } else {
        this.book_model_data.value_vehicle = null;
      }
    },
    // value_datePicker(newValue) {
    //   this.value_selected_slot = "";
    //   this.items_available_slots = [];
    //   if (
    //     typeof this.value_vehicle == "object" &&
    //     typeof this.value_datePicker == "string"
    //   ) {
    //     let car_details = {
    //       car: this.value_vehicle.name,
    //       date: this.value_datePicker,
    //     };

    //     this.get_available_slot(car_details).then((response) => {
    //       this.items_available_slots = response;
    //     });
    //     this.book_model_data.value_datePicker = newValue;
    //   } else {
    //     this.book_model_data.value_datePicker = null;
    //   }
    // },
    // value_selected_slot(newValue) {
    //   if (typeof this.value_selected_slot == "object") {
    //     this.book_model_data.value_selected_slot = newValue;
    //   } else {
    //     this.book_model_data.value_selected_slot = null;
    //   }
    // },
    vehicleResult(){
      // if(this.vehicleResult.length == 1) {
      //   setTimeout(() => {
      //     // this.$refs[this.vehicleResult[0].name][0].click()
      //     document.getElementById(this.vehicleResult[0].name).click();
      //     // console.log("after click "+this.vehicleResult[0].name);
      //   }, 1200);
      // }
    },
    // book_model_data: {
    //   handler() {
    //     if (
    //       // typeof this.value_type == "object" &&
    //       // typeof this.value_brand == "object" &&
    //       typeof this.value_vehicle == "object" &&
    //       typeof this.value_datePicker == "string" &&
    //       // typeof this.value_model == "string" &&
    //       // typeof this.value_transmission == "string" &&
    //       // typeof this.value_type_of_fuel == "string" &&
    //       typeof this.value_selected_slot == "object"
    //     ) {
    //       this.$emit("allow-booking-form", false);
    //       var booking_data = {
    //         car: this.value_vehicle.name,
    //         booking_date: this.value_datePicker,
    //         from_time: this.value_selected_slot.from_time,
    //         to_time: this.value_selected_slot.to_time,
    //       };
    //       this.$emit("booking-data", booking_data);
    //     } else {
    //       this.$emit("allow-booking-form", true);
    //     }
    //   },
    //   deep: true,
    // },
  },
  data: () => ({
    base_url: "https://pf.mtsnetworks.de/",
    car_type_model_details: "",
    value_type: "",
    value_brand: "",
    value_vehicle: "",
    current_date: new Date().toISOString().substr(0, 10),
    value_datePicker: new Date().toISOString().substr(0, 10),
    value_model: null,
    value_transmission: null,
    value_type_of_fuel: null,
    value_selected_slot: "",
    vehicle_type_filter: "",
    brand_details_filter: "",
    vehicle_details: "",
    brand_details: null,
    vehicleResult: [],
    isNoVehicle : false,
    items_model: [],
    items_transmission: [],
    items_type_of_fuel: [],
    items_available_slots: [],
    book_model_data: {
      value_type: "",
      value_brand: "",
      value_vehicle: "",
      value_datePicker: new Date().toISOString().substr(0, 10),
      value_model: "",
      value_transmission: "",
      value_type_of_fuel: "",
      value_selected_slot: "",
    },
    watcher_status: false,
    activeId: "",
    active_type_id:""
  }),
};
</script>


<style>
.custom-datepicker .v-date-picker-table .v-btn {
  font-size: 16px;
}
.v-list-item__title {
  text-transform: uppercase;
}
.v-slide-group__content .v-card--link:before,
.v-card .v-card--link:before {
  background: white;
}
.v-slide-group__content .theme--light.v-sheet--outlined,
.v-card .theme--light.v-sheet--outlined {
  border-color: #1976d2;
  border-width: 2px;
}
.location-label {
  color: #1976d2;
}
.max-width-1185px{
  max-width: 1185px !important;
}
.car-card{
  width: 320px;
  margin: 0 20px;
}
@media only screen and (max-width: 600px) {
  .car-card{
    width: 200px;
    margin: 0;
  }
}
.v-slide-group__prev i.v-icon {
    font-size: 50px;
}
.v-slide-group__next i.v-icon {
    font-size: 50px;
}
.v-stepper__wrapper{
  overflow: unset !important;
}
.VueCarousel-navigation-next{
  right: 20px !important;
}
.VueCarousel-navigation-prev{
  left: 20px !important;
}
.vehicle_type_image{
  width: 100px;
}
@media only screen and (max-width: 786px) {
  .vehicle_type_image{
    width: 60px;
  }
}
</style>